.description_list
    display: inline-flex
    flex-wrap: wrap
    grid-gap: .5rem 2rem

    margin: .25rem 0
    padding: .5rem 1rem
    border-radius: .25rem

    &.inactive
        opacity: .5

    div.description
        grid-column: 1 / -1

    dt
        font-size: .8rem
        color: var(--clr-almost-black)

    dd.red
        color: red
