#page_root_component_login
    z-index: 4

    .disabled
        opacity: 0.4

    .component_loading
        height: 10rem

    section
        width: 20rem
        max-width: 94vw

        header
            grid-gap: 0
            grid-template-columns: 1fr

            span
                font-size: .8rem
                color: red

        input
            text-align: center
            width: 100%
