.component_customtable
    display: grid
    grid-gap: .5rem

    > label
        justify-self: start

        display: grid
        grid-auto-flow: column
        grid-template-columns: 1rem 1fr
        grid-gap: .25rem 1rem

        padding: .5rem 1rem .5rem 1.25rem
        border-radius: .5rem
        background: var(--clr-almost-white)

        picture
            grid-row: 1 / span 2

        span
            display: inline-flex
            align-items: center
            grid-gap: .5rem

            select
                background: var(--clr-darker-white)
                border-radius: .25rem
                border: none
                padding-left: .25rem

        input
            width: 100%
            border: none
            border-radius: .25rem
            display: block

    .table
        tr
            display: grid
            background: transparent
            z-index: 1

        thead
            th
                padding: .25rem

                div
                    white-space: normal

        tbody
            tr
                transition: background .25s

                &:nth-last-child(even)
                    background: var(--clr-almost-white)

                &:hover, &.row-select-selected, &.row-select-single-selected
                    background: var(--clr-darker-white)

                td
                    padding: .25rem .5rem

                    .red
                        color: red

                    div
                        white-space: normal

                    button, a
                        display: inline
                        width: 24px
                        height: 24px
                        padding: .1rem .5rem

                    .component_customtable_actions
                        display: inline-grid
                        grid-auto-flow: column

    .table_pagination
        justify-self: end

        td
            border: none

            *
                font-family: "Poppins", sans-serif

            div
                border-radius: .5rem
                background: var(--clr-almost-white)
                overflow: scroll

@media print
    .component_customtable
        > label
            display: none
