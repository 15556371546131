.pop_form
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 3

    backdrop-filter: blur(3px)
    background: transparent

    display: grid
    align-content: center
    justify-items: center

    > section
        display: grid
        justify-content: center
        grid-gap: 1rem

        max-width: 90vw
        max-height: 94vh
        background: var(--clr-semi-white)
        border: 1px solid var(--clr-darker-white)
        padding: 2rem
        border-radius: .5rem
        overflow: scroll

        header
            display: grid
            grid-template-columns: 1fr auto
            grid-gap: 1rem 2rem

            h1
                font-size: 1.75rem

            button:hover
                opacity: .5
                cursor: pointer

        form
            display: grid

            > button
                margin-top: .5rem

        .form
            background: var(--clr-almost-white)
