.component_loading
    width: 100%
    height: 100%
    height: 15rem

    display: grid
    justify-items: center
    align-content: center

    span
        font-size: 1.4rem
        font-weight: bold
