#page_root_component_notifications
    position: fixed
    left: 50%
    transform: translate(-50%, 0)
    list-style-type: none
    width: 96vw
    z-index: 4
    top: 2vh
    overflow: scroll
    max-height: 100%

    display: grid
    grid-gap: .5rem
    justify-content: center

    li
        position: relative
        padding: .25rem 1.5rem
        border-radius: .75rem
        transition: top .25s
        background: var(--clr-almost-white-transparent)
        backdrop-filter: blur(2px)
        color: var(--clr-blue)
        text-align: center
        border: 1px solid var(--clr-blue)

        top: -10vh
        opacity: 0
        animation: pop .25s forwards

        &.success
            color: var(--clr-green)
            border-color: var(--clr-green)

        &.error
            color: var(--clr-lighter-red)
            border-color: var(--clr-lighter-red)

@keyframes pop
    from
        top: -10vh
        opacity: 0
    to
        top: 0
        opacity: 1
