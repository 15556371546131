.form
    display: inline-flex
    flex-wrap: wrap
    grid-gap: .5rem 1rem
    align-items: flex-start

    position: relative
    padding: .5rem 1rem
    border-radius: .5rem
    background: var(--clr-almost-white)

    label
        align-self: flex-start

        input
            max-width: 100%

            &[type="checkbox"]
                margin: .2rem 0
                height: 1.5rem

    &:not(.withoutHeader)
        > div
            width: 7rem
            height: 2rem
            align-self: flex-end

        .action_button
            position: absolute
            width: 7rem
            right: 1rem
            padding: .25rem 0
            align-self: end

            &.reset
                right: 9rem

@media only screen and (max-device-width: 768px)
    .form
        display: grid

        input
            width: 100% !important

        &:not(.withoutHeader)
            > div
                display: none

            .action_button
                position: relative
                width: 100%
                right: unset

                &.reset
                    right: unset
