#page_root
    grid-template-columns: auto minmax(auto, 70rem)
    justify-content: center
    align-items: start
    align-content: start
    min-height: 95vh

    > main
        display: grid
        grid-gap: 2rem

        h2, h3
            display: grid
            grid-template-columns: auto 1fr auto
            align-items: center
            grid-gap: .5rem 1rem

            nav
                display: inline-flex
                flex-wrap: wrap
                grid-gap: .25rem 1.25rem
                padding: 0 1rem

                a
                    text-transform: lowercase

            menu
                display: inline-flex
                flex-wrap: wrap
                justify-content: flex-end
                grid-gap: .25rem 1rem

        h2
            font-size: 1.75rem

        h3
            font-size: 1.5rem

@media only screen and (max-device-width: 768px)
    #page_root
        grid-template-columns: 1fr

        > main h2, > main h3
            display: inline-flex
            grid-gap: .5rem 1rem
            flex-wrap: wrap

@media print
    #page_root
        grid-template-columns: 1fr

        > main h2, > main h3
            menu
                display: none
