#page_root_component_footer
    padding: 2rem 10vw
    background: rgb(50, 50, 50)

    *
        color: white

    .component_navigation
        width: 100%
        display: inline-flex
        flex-wrap: wrap
        grid-gap: 1rem

        li
            margin: 0

    p
        margin: 1rem 0
        color: rgb(200, 200, 200)
        font-size: .9rem

@media print
    #page_root_component_footer
        display: none
