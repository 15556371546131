.component_navigation
    width: 9rem

    li
        display: grid

        &:not(:first-of-type)
            margin-top: .75rem

    a, span
        display: inline-flex
        grid-template-columns: auto 1fr
        align-items: center
        grid-gap: .5rem

        color: var(--clr-black)
