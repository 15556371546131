#page_root_component_aside
    position: sticky
    top: 1rem
    padding: 1rem 2.5rem
    border-radius: .5rem
    z-index: 2

    display: grid
    grid-gap: 1rem

@media only screen and (max-device-width: 768px)
    #page_root_component_aside
        position: fixed
        transform: translate(calc(-100% - 2rem), 0)
        transition: transform .25s
        padding: 1rem
        background: var(--clr-almost-white)

        &.open
            transform: translate(-1rem, 0)

        &::after
            content: "nabídka"
            position: absolute
            top: 20%
            right: -3.75rem
            transform: rotate(-90deg)
            background: var(--clr-almost-white)
            padding: .25rem 1rem
            border-bottom-right-radius: .5rem
            border-bottom-left-radius: .5rem
            font-weight: bold

@media print
    #page_root_component_aside
        display: none
