.action_button
    padding: .25rem 1.5rem
    border: none
    border: 2px solid transparent
    border-radius: .25rem
    color: var(--clr-black)
    font-size: .9rem

    font-weight: bold
    background: var(--clr-lighter-blue)
    transition: background .5s

    justify-self: center

    &:not(.inactive):hover
        border: 2px solid var(--clr-lighter-blue)
        background: transparent
        cursor: pointer

    &.inactive
        opacity: .5
        background: var(--clr-darker-white)

    &.active
        border-color: var(--clr-lighter-red)
        background: transparent

        &:hover
            background: var(--clr-lighter-red)
            border-color: transparent

    &.red
        background: var(--clr-lighter-red)

        &:hover
            border: 2px solid var(--clr-lighter-red)
            background: transparent
