.page_root_component_init
    &.pop_form > section
        max-width: 40rem

        menu
            display: grid
            grid-template-columns: auto 1fr auto
            justify-self: space-between
            grid-gap: .5rem

            button
                &.disabled
                    opacity: .5

                &:not(.disabled)
                    transition: .25s

                    &:hover
                        cursor: pointer
                        opacity: .6

        article
            padding: 1rem 2rem

        section
            border: none

            &.page_root_component_init_admin_password form
                display: grid

    .form
        width: 100%

        display: inline-flex
        flex-wrap: wrap
