:root
    --clr-black: black
    --clr-almost-black: rgb(100, 100, 100)
    --clr-white: white
    --clr-almost-white: rgb(245, 245, 245)
    --clr-semi-white: rgb(240, 240, 240)
    --clr-almost-white-transparent: rgba(200, 200, 200, .3)
    --clr-darker-white: rgb(230, 230, 230)
    --clr-blue: rgb(76, 105, 179)
    --clr-lighter-blue: rgb(146, 188, 222)
    --clr-lighter-red: #d47a7a
    --clr-active: #8d43a1
    --theme-ui-colors-text: black
    --clr-green: #588553

@media (prefers-color-scheme: dark)
    :root
        --theme-ui-colors-text: white
        --clr-black: white
        --clr-almost-black: rgb(220, 220, 220)
        --clr-white: black
        --clr-almost-white: rgb(35, 35, 35)
        --clr-semi-white: rgb(55, 55, 55)
        --clr-almost-white-transparent: rgba(35, 35, 35, .7)
        --clr-darker-white: rgb(85, 85, 85)
        --clr-blue: rgb(76, 105, 179)
        --clr-lighter-blue: rgb(37, 76, 108)
        --clr-lighter-red: #b75050
        --clr-green: #7fc279
        --clr-active: #ba7bcb

@media print
    :root
        --clr-black: black
        --clr-almost-black: rgb(100, 100, 100)
        --clr-white: white
        --clr-almost-white: rgb(245, 245, 245)
        --clr-semi-white: rgb(240, 240, 240)
        --clr-almost-white-transparent: rgba(200, 200, 200, .3)
        --clr-darker-white: rgb(230, 230, 230)
        --clr-blue: rgb(76, 105, 179)
        --clr-lighter-blue: rgb(146, 188, 222)
        --clr-lighter-red: #d47a7a
        --clr-active: #8d43a1
        --theme-ui-colors-text: black
        --clr-green: #588553
