.component_customselect
    max-width: calc(96vw - 4rem)

    > div[class*="control"]
        background: var(--clr-darker-white)
        min-height: unset
        border: none

        div
            &[class*="singleValue"]
                color: var(--clr-black) !important

            &[class*="multiValue"]
                background: var(--clr-almost-white)
                padding-left: .25rem
                font-size: 1rem

                > div
                    color: var(--clr-black)
                    background: var(--clr-almost-white)
                    border-radius: .25rem

                div[class*="MultiValueRemove"]:hover
                    background: var(--clr-lighter-red)
                    cursor: pointer

            &[class*="Input"]
                min-width: 5rem
                background: transparent
                color: var(--clr-black)
                border-bottom: 1px solid var(--clr-black)

        > div[class*="IndicatorsContainer"] div[class*="indicatorContainer"]
            padding: 0 .5rem

    > div[class*="menu"]
        background: var(--clr-darker-white)
        margin: 0
        border-radius: .5rem
        z-index: 5
        overflow: hidden

        div
            &[class*="MenuList"]
                padding: 0

            div
                background: transparent

                &[class*="option"]
                    color: var(--clr-black)

                    &:hover
                        cursor: pointer
                        background: var(--clr-almost-white)
                        transition: background .25s
