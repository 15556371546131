#page_root_component_header
    grid-column: 1 / span 2

    display: grid
    grid-auto-flow: column
    justify-content: space-between
    align-items: center
    grid-gap: 1rem 2rem
    grid-template-columns: 1fr auto auto

    a
        h1
            color: var(--clr-almost-black)

    h1
        color: var(--clr-black)

    #page_root_component_header_session
        display: grid
        grid-auto-flow: column
        grid-gap: 1rem
        align-items: center

        border-radius: .5rem

        picture
            border: 2px solid var(--clr-black)
            background: rgba(200, 200, 200, .5)
            border-radius: 100%
            padding: .35rem

            &.ROLE_ADMIN
                border-color: var(--clr-lighter-red)

            &.ROLE_TECHNICIAN
                border-color: var(--clr-lighter-blue)

            &.ROLE_MANIPULATOR
                border-color: var(--clr-active)

@media only screen and (max-device-width: 768px)
    #page_root_component_header
        grid-column: 1

        grid-template-columns: 1fr auto
        grid-auto-flow: row
        justify-content: space-between
        justify-items: right

        #page_root_component_header_title
            grid-column: 1 / span 2
            grid-row: 2
