#page_error
    margin-top: 50vh
    transform: translate(0, -50%)

    display: grid
    justify-items: center

    a
        font-size: 1.25rem
        justify-self: center
