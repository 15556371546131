@import font
@import theme

@import classes/description_list
@import classes/pop_form
@import classes/form
@import classes/notification_span
@import classes/action_button
@import classes/empty_message

*
    font-family: "Poppins", sans-serif
    box-sizing: border-box
    padding: 0
    margin: 0
    color: var(--clr-black)

body
    background: var(--clr-white)

    .page
        padding: 5vh 2rem

        display: grid
        grid-gap: 2rem

        main
            section
                display: grid
                grid-gap: .5rem

                &:not(.noBreak):not(:last-child)
                    padding-bottom: 2rem
                    border-bottom: 1px solid var(--clr-darker-white)

a, .link
    text-decoration: none
    color: var(--clr-active)
    transition: opacity .25s
    font-weight: bold
    font-size: 1rem

    &:hover
        cursor: pointer
        opacity: .5

button
    display: block
    background: transparent
    border: none

picture
    display: grid
    justify-content: center
    align-content: center

label
    display: grid
    grid-gap: .25rem
    font-size: .8rem

input
    font-size: 1rem
    padding: .25rem 1rem
    border: none
    border-radius: .25rem
    background: var(--clr-darker-white)

label + span, input + span, .component_customselect + span
    font-size: .8rem
    color: red

.MuiList-root
    background: var(--clr-almost-white) !important

    li
        &.Mui-selected, &:hover
            background: var(--clr-darker-white) !important
